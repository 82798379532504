import React from 'react'

const SendOfferMails = () => {
  return (
    <div>
        SendOfferMails
    </div>
  )
}

export default SendOfferMails
